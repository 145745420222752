import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SystemRoutes } from '@enums';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly toastr = inject(ToastrService);
  private readonly translateService = inject(TranslateService);
  private readonly router = inject(Router);

  systemRoutes = SystemRoutes;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorCode = error.status;
        const errorMessage = error.error ? error.error.Message : error.statusText;
        //TODO gestire errori con Anna!
        switch (errorCode) {
          case 400:
          case 404:
          case 500:
            console.error('Internal Server Error:', error.message);
            break;
          case 502:
            this.toastr.error(errorMessage, this.translateService.instant('common.error', { code: errorCode }));
            break;

          case 403:
            //this.toastr.error(errorMessage, this.translateService.instant('common.error', {code: errorCode}), this.toastOptions);
            this.router.navigateByUrl('/error/403');
            break;
          /*
           * case 401:
           * */

          default:
            this.toastr.error(errorMessage, this.translateService.instant('common.error', { code: errorCode }));
            break;
        }

        return throwError(() => error);
      })
    );
  }
}

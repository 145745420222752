import {
  faNoteSticky as faNoteStickyLight,
  faPlaneDeparture,
  faTriangleExclamation,
  faFileExcel,
  faMemo,
  faPencil as faPencilLight,
  faPlus,
  faTrash,
  faCalendar,
  faList,
  faChartPie,
  faTimes,
  faInfo,
  faFileCheck,
  faGrid2,
  faCalendarDay,
  faClock,
  faSquareRootVariable,
  faBan as faBanLight,
  faBriefcase,
  faClockRotateLeft,
  faX as faXLight,
  faCog,
  faUser as faUserLight,
  faCabinetFiling,
  faChevronLeft as faCrevronLeftLight,
  faPen,
  faCircleCheck as faCircleCheckLight,
  faFilter as faFilterLight,
  faStamp,
  faCalendarArrowUp,
  faCalendarArrowDown,
  faShareFromSquare,
  faCalendarClock,
  faMemoCircleInfo,
  faWrench,
  faFileLines as faFileLinesLight,
} from '@fortawesome/pro-light-svg-icons';

import {
  faNoteSticky as faNoteStickySolid,
  faRegistered,
  faCircle,
  faPencil,
  faSitemap,
  faUser as faUserSolid,
  faUserVneckHair,
  faSquare,
  faCar,
  faPhone,
  faPlane,
  faPlus as faPlusSolid,
  faFile,
  faReceipt,
  faHouse,
  faUtensils,
  faTrash as faTrashSolid,
  faGridRound,
  faFilter,
  faEllipsisVertical,
  faNoteSticky,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faUser,
  faBell,
  faFileSignature,
  faGlobePointer,
  faBuilding,
  faCheck,
  faX,
  faCircleInfo,
  faUtensils as faUtensilsRegular,
  faPencil as faPencilRegular,
  faTrash as faTrashRegular,
  faPlus as faPlusRegular,
  faArrowRotateLeft,
  faBan,
  faCircleCheck,
  faCircleExclamation,
  faFileMagnifyingGlass,
  faCircleArrowRight,
  faCircleArrowLeft,
  faFileArrowDown,
  faCalendar as faCalendarRegular,
  faChevronLeft,
  faChevronDown,
  faGear,
  faBars,
  faXmark,
  faChevronRight,
  faCircleQuestion,
  faCircleXmark,
  faUpload,
  faExclamationCircle,
  faUserCheck,
  faUserTimes,
  faArrowDownToLine,
  faFileLines,
  faArrowDown,
} from '@fortawesome/pro-regular-svg-icons';

export function initializeIcons() {
  return [
    faNoteStickyLight,
    faPlaneDeparture,
    faTriangleExclamation,
    faNoteStickySolid,
    faRegistered,
    faCircleQuestion,
    faCircle,
    faCalendarArrowUp,
    faWrench,
    faPencil,
    faUtensilsRegular,
    faFilterLight,
    faFilter,
    faUser,
    faBell,
    faChevronDown,
    faGear,
    faFile,
    faGridRound,
    faInfo,
    faTrashSolid,
    faHouse,
    faCalendarRegular,
    faCog,
    faCalendarArrowDown,
    faCircleXmark,
    faXLight,
    faUserLight,
    faGrid2,
    faCalendarDay,
    faCalendar,
    faUserTimes,
    faMemoCircleInfo,
    faList,
    faChartPie,
    faClock,
    faBriefcase,
    faCabinetFiling,
    faCalendarClock,
    faNoteSticky,
    faUpload,
    faFileLinesLight,
    faFileLines,
    faArrowDown,
    faFileSignature,
    faTimes,
    faSquareRootVariable,
    faPlusSolid,
    faUserCheck,
    faGlobePointer,
    faCircleCheckLight,
    faCircleInfo,
    faCircleArrowRight,
    faChevronRight,
    faChevronLeft,
    faPencilRegular,
    faTrashRegular,
    faSitemap,
    faCrevronLeftLight,
    faBanLight,
    faUserSolid,
    faUserVneckHair,
    faArrowDownToLine,
    faClockRotateLeft,
    faSquare,
    faArrowRotateLeft,
    faFileCheck,
    faBan,
    faCircleCheck,
    faCircleExclamation,
    faFileMagnifyingGlass,
    faBars,
    faBuilding,
    faEllipsisVertical,
    faFileExcel,
    faCar,
    faPhone,
    faFileArrowDown,
    faPlane,
    faReceipt,
    faPen,
    faStamp,
    faShareFromSquare,
    faUtensils,
    faExclamationCircle,
    faPlusRegular,
    faMemo,
    faPencilLight,
    faCircleArrowLeft,
    faCheck,
    faX,
    faXmark,
    faPlus,
    faTrash,
  ];
}

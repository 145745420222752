import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { UserService } from './core/services/user/user.service';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, SilentRequest } from '@azure/msal-browser';

@Injectable({
  providedIn: 'root',
})
export class AppLoaderService {
  private readonly translate = inject(TranslateService);
  private readonly userService = inject(UserService);
  private readonly authService = inject(MsalService);

  async initializeApp(): Promise<void> {
    try {
      // Set fallback language
      this.translate.setDefaultLang('en-US');

      const account = this.authService.instance.getActiveAccount();

      if (!account) {
        await firstValueFrom(this.translate.use('en-US'));
        return;
      }

      try {
        const silentRequest: SilentRequest = {
          scopes: ['https://VelaSuiteTest.onmicrosoft.com/tasks-api/ReadAccess', 'openid'],
          account: account,
        };

        // Try to acquire token silently
        await firstValueFrom(this.authService.acquireTokenSilent(silentRequest));

        // Get user profile and set language
        const userProfile = await firstValueFrom(this.userService.setUserProfile());

        if (userProfile?.language) {
          await firstValueFrom(this.translate.use(userProfile.language));
        } else {
          await firstValueFrom(this.translate.use('en-US'));
        }
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          await firstValueFrom(
            this.authService.loginRedirect({
              scopes: ['https://VelaSuiteTest.onmicrosoft.com/tasks-api/ReadAccess', 'openid'],
            })
          );
        } else {
          console.error('Error acquiring token:', error);
          await firstValueFrom(this.translate.use('en-US'));
        }
      }
    } catch (error) {
      console.error('Error in app initialization:', error);
      await firstValueFrom(this.translate.use('en-US'));
      throw error;
    }
  }
}
